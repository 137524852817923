<template>
  <el-container class="container">
    <div class="select_wrapper">
      <div class="select_wrapper_title">身份选择</div>
      <div class="select_wrapper_des">请选择一个身份进行账号绑定!</div>
      <div
        class="item"
        @click="toUser">
        <div class="item_title">用户</div>
        <div class="item_des">在平台中接受任务并完成任务</div>
      </div>
      <div
        class="item"
        @click="toCompany">
        <div class="item_title">企业</div>
        <div class="item_des">在平台中发布任务和奖励</div>
      </div>
    </div>
  </el-container>
</template>

<script>
import api from '@/api/index'
export default {
  methods: {
    toUser () {
      api.middleLogin({
        role: 0
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('角色绑定成功')
          this.$store.dispatch('getUserInfo')
          this.$router.push('/user')
        } else {
          this.$message.error('绑定失败')
        }
      })
    },
    toCompany () {
      api.middleLogin({
        role: 1
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('角色绑定成功')
          this.$store.dispatch('getUserInfo')
          this.$router.push('/company')
        } else {
          this.$message.error('绑定失败')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  .select_wrapper {
    width: 500px;
    height: 600px;
    background: #ffffff;
    padding: 30px;
    .select_wrapper_title {
      font-size: 20px;
      font-weight: bold;
      height: 100px;
      line-height: 100px;
    }
    .select_wrapper_des {
      font-size: 16px;
      font-weight: 550;
    }
    .item {
      margin-top: 30px;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        background: #f2f2f2;
      }
      .item_title {
        font-size: 18px;
        height: 50px;
        line-height: 50px;
      }
      .item_des {
        color: #cbcbcb;
        font-size: 14px;
      }
    }
  }
}
</style>
